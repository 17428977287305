<div
  class="d-flex align-items-center justify-content-between c-wrapperbox dotted"
  *ngIf="validMode"
>
  <div class="p-3">
    <div class="mx-auto">
      <div class="text-center mx-auto">
        <div>
          <i class="fa commute_icon" [ngClass]="commuteIcon"></i>
        </div>
        <div class="extra-small-heading text-uppercase secondary-text-color">
          {{ commuteType }}
        </div>
      </div>
    </div>
  </div>
  <div class="commute_details p-3">
    <div class="align-items-center px-lg-0 pl-4" >
      
        <span> About {{ commuteTime.text }} </span>
        <span *ngIf="previousLocation"> from {{previousLocation}}</span>
      
    </div>
  </div>
  <div class=" small-heading local_commute_options p-3" *ngIf="direction !== ''">
    
      <a [href]="direction" target="_blank">
        <i class="fa fa-directions primary-text-color"></i>
      </a>
  </div>
</div>
